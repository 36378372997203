export default {
  backWidth: 512,
  backHeight: 432, 
  bushesWidth: 508,
  bushesHeight: 79, 
  groundWidth: 68, 
  groundHeight: 24, 
  marioWidth: 48, 
  marioHeight: 54
} 
