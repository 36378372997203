import React, { Component } from 'react';
import SuperMarioReact from './react-super-mario';

class App extends Component {
  render() {
    return <SuperMarioReact />;
  }
}

export default App;
